import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dayTypes, MONTHS } from './Types';
import './MonthlyReports.css';

const styles = theme => ({
    container: {
        padding: theme.spacing.unit,
        textAlign: 'center'
    }
});

const CustomTableCell = withStyles((theme) => ({
    ['@media print']: {// eslint-disable-line no-useless-computed-key
        body: {
            fontSize: 8,
            padding: '3px 20px',
            height: 'auto',
        },
    }
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
    ['@media print']: {// eslint-disable-line no-useless-computed-key
        root: {
            height: 'auto',
        },
    }
}))(TableRow);

class MonthlyReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    filterLogsBySelectedMonth = (log) => {
        let date = new Date(log.date);
        return ((this.props.month) === date.getMonth()) && ((this.props.year) === date.getFullYear());
    }

    // getWeek = (rawDate) => {
    //     let date = new Date(rawDate);
    //     let onejan = new Date(date.getFullYear(), 0, 1);
    //     let week = Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    //     console.log('getWeek', rawDate, week, this.getWeekNumber(date));
    //     return week;
    // }

    getWeek = (rawDate) => {
        let d = new Date(rawDate);
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }


    renderSumRow = (currWeek, weekSum) => {
        return (
            <CustomTableRow className='no-print' key={`SUM-WEEK-${currWeek}`}>
                <CustomTableCell colSpan='3'><b>{'Resum Setmana '}{currWeek}</b></CustomTableCell>
                <CustomTableCell colSpan='2'><b>{`${weekSum}h`}</b></CustomTableCell>
            </CustomTableRow>
        );
    }

    getLog = (monthLogs, date) => {
        for (let i = 0; i < monthLogs.length; i++) {
            let currLog = monthLogs[i];
            let currDate = new Date(currLog.date);
            currDate.setHours(0);
            currDate.setMinutes(0);
            if (currDate.getTime() === date.getTime()) {
                return currLog;
            }
        }
        return undefined;
    }

    prepareMonth = () => {
        const { logs, month, year } = this.props;
        let monthLogs = logs.filter(this.filterLogsBySelectedMonth);

        let firstDay = new Date(year, month, 1);
        let lastDay = new Date(year, month + 1, 0);
        if (lastDay > new Date()) {
            lastDay = new Date();
        }

        let logResult = [];
        for (let d = firstDay; d <= lastDay; d.setDate(d.getDate() + 1)) {
            let year = d.getFullYear();
            let month = ('0' + (d.getMonth() + 1)).slice(-2);
            let day = ('0' + d.getDate()).slice(-2);
            let stringDate = `${year}-${month}-${day}`;
            let currLog = this.getLog(monthLogs, d);
            if (currLog) {
                logResult.push(currLog);
            } else {
                let dayType = 'N';
                if (this.props.holidays.indexOf(stringDate) !== -1) {
                    dayType = 'H';
                } else if (d.getDay() === 6 || d.getDay() === 0) {
                    dayType = 'WE';
                }
                logResult.push({
                    date: stringDate,
                    dayType,
                    entries: [],
                    totalHours: 0,
                });
            }
        }
        return logResult;
    }

    render() {
        const { classes, month, year, user, logs } = this.props;
        let preparedLogs = [];
        if (logs) {
            preparedLogs = this.prepareMonth();
        }

        let weekSum = 0;
        let currWeek = -1;
        let renderSum = undefined;
        return (
            <div className={classes.container}>
                <h3>{'Registre de Jornada'}</h3>
                <div style={{ padding: '15px', border: '1px solid rgba(224, 224, 224, 1)', fontSize: '12px' }}>
                    <div>
                        {`Treballador: ${user ? user.name : '--'}`}
                    </div>
                    <div>
                        {`Mes i any: ${MONTHS[month]} ${year}`}
                    </div>
                </div>
                <br />
                {preparedLogs &&
                    <Table padding='dense'>
                        <TableHead>
                            <TableRow>
                                <TableCell>{'Dia'}</TableCell>
                                <TableCell>{'Entrada'}</TableCell>
                                <TableCell>{'Sortida'}</TableCell>
                                <TableCell>{'Total Hores'}</TableCell>
                                <TableCell>{'Tipus dia'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {preparedLogs.filter(this.filterLogsBySelectedMonth).map(log => {
                                renderSum = undefined;
                                if (currWeek !== this.getWeek(log.date)) {
                                    if (currWeek !== -1) {
                                        renderSum = this.renderSumRow(currWeek, weekSum);
                                    }
                                    currWeek = this.getWeek(log.date);
                                    weekSum = 0;
                                };

                                weekSum += log.totalHours;

                                if (log.entries.length > 0) {
                                    let entrades = log.entries.filter((entry) => {
                                        return entry.action === 'enter';
                                    });
                                    let sortides = log.entries.filter((entry) => {
                                        return entry.action === 'leave';
                                    });

                                    return (
                                        entrades.map((entry, i) => {
                                            return (
                                                <React.Fragment key={entry.date}>
                                                    {i === 0 && renderSum}
                                                    <CustomTableRow key={entry.date}>
                                                        {i === 0 && <CustomTableCell rowSpan={entrades.length}>{new Date(log.date).toLocaleDateString()}</CustomTableCell>}
                                                        <CustomTableCell className='border'>{new Date(entry.date).toLocaleTimeString()}</CustomTableCell>
                                                        <CustomTableCell className='border'>{sortides[i] ? new Date(sortides[i].date).toLocaleTimeString() : '-'}</CustomTableCell>
                                                        {i === 0 && <CustomTableCell className='border' rowSpan={entrades.length}>{log.totalHours}</CustomTableCell>}
                                                        {i === 0 && <CustomTableCell rowSpan={entrades.length}>{dayTypes[log.dayType]}</CustomTableCell>}
                                                    </CustomTableRow>
                                                </React.Fragment>
                                            );
                                        })
                                    );
                                } else {
                                    return (
                                        <React.Fragment key={log.date}>
                                            {renderSum}
                                            <CustomTableRow key={log.date}>
                                                <CustomTableCell>{new Date(log.date).toLocaleDateString()}</CustomTableCell>
                                                <CustomTableCell className='border'>{'-'}</CustomTableCell>
                                                <CustomTableCell className='border'>{'-'}</CustomTableCell>
                                                <CustomTableCell className='border'>{`${log.totalHours}h`}</CustomTableCell>
                                                <CustomTableCell>{dayTypes[log.dayType]}</CustomTableCell>
                                            </CustomTableRow>
                                        </React.Fragment>
                                    );
                                }
                            })}
                            {this.renderSumRow(currWeek, weekSum)}
                        </TableBody>
                    </Table>
                }
                <div className='pagebreak' />
            </div>
        );
    }
}
MonthlyReports.propTypes = {
    classes: PropTypes.object.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    user: PropTypes.object,
    logs: PropTypes.array,
    holidays: PropTypes.array,
};
export default withStyles(styles)(MonthlyReports);