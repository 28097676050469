import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TimeControlService from '../ApiClients/TimeControlService';

const styles = theme => ({
    container: {
        padding: theme.spacing.unit,
        textAlign: 'center'
    },
});

class HourPool extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {
        this.loadSummary();
    }

    loadSummary = async () => {
        try {
            let response = await TimeControlService.getSummary(new Date().getFullYear());
            // console.log('loadSummary', response);
            this.setState({ summary: response });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { classes } = this.props;
        const { summary } = this.state;
        return (
            <div className={classes.container}>
                <h1>{'Bossa d\'Hores'}</h1>
                {summary &&
                    <div>
                        <p>
                            <b>{'Balanç anual d\'hores: '}</b><br />
                            <span>
                                {`${Math.round(summary.totalPeriodHours * 100) / 100}h de ${summary.expectedPeriodHours}h`}
                            </span>
                            <br />
                            <span style={{
                                color: summary.workHoursBalance >= 0 ? '#009900' : '#e50000'
                            }}>
                                {Math.round(summary.workHoursBalance * 100) / 100}
                                {summary.workHoursBalance >= 0 ?
                                    ' hores sobrants'
                                    :
                                    ' hores pendents'
                                }
                            </span>

                        </p>
                        <p>
                            <b>{'Resum de dies: '}</b><br />
                            {'Dies Jornada Normal: '}{summary.daysByType.N}<br />
                            {'Dies de Baixa: '}{summary.daysByType.B}<br />
                            {'Dies de Viatge: '}{summary.daysByType.V}<br />
                            {'Dies de Vacances: '}
                            <span style={{
                                color: summary.daysByType.VC <= 24 ? '#009900' : '#e50000'
                            }}>
                                {summary.daysByType.VC}{' de 24'}
                            </span>
                        </p>
                    </div>
                }
            </div>
        );
    }
}
HourPool.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(HourPool);