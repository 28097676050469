const config = {
    apiGateway: [
        {
            name: 'TimeControlService',
            endpoint: 'https://heu3uww014.execute-api.eu-west-1.amazonaws.com/dev',
        },
    ],
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_Wnv6zNMp4',
        APP_CLIENT_ID: '22tnlgf9mr13fvg4vfevoq4r09',
    }
};

export default config;