const dayTypes = {
    'N': 'Jornada Normal',
    'B': 'Baixa Laboral',
    'V': 'Viatge Laboral',
    'VC': 'Vacances',
    'WE': 'Cap de Setmana',
    'H': 'Festa'
};
const actionTypes = {
    'enter': 'Entrada',
    'leave': 'Sortida',
};

const MONTHS = [
    'Gener',
    'Febrer',
    'Març',
    'Abril',
    'Maig',
    'Juny',
    'Juliol',
    'Agost',
    'Setembre',
    'Octubre',
    'Novembre',
    'Desembre'
];

const YEARS = [
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
];

export { dayTypes, actionTypes, MONTHS, YEARS };