import 'date-fns';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import TimeControlService from '../ApiClients/TimeControlService';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { cloneDeep } from 'lodash';
import { dayTypes, actionTypes } from './Types';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Auth } from 'aws-amplify';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    container: {
        padding: theme.spacing.unit,
        textAlign: 'center',
        position: 'relative'
    },
    summary: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '10px',
        borderRadius: '5px'
    },
    margin: {
        margin: theme.spacing.unit
    },
    heading: {
        fontSize: '1.3rem',
        fontWeight: 'bold'
    }
});

class TodayControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dayTranslate: 0,
            showTimePicker: false
        };
    }

    componentDidMount() {
        this.loadToday();
        this.loadSummary();
        this.loadUserData();
    }

    loadUserData = async () => {
        let user = await Auth.currentAuthenticatedUser();
        const { attributes } = user;
        //console.log('user', user)
        let isAdmin = false;
        if (attributes['custom:admin'] === 'yes') {
            isAdmin = true;
        }
        this.setState({
            isAdmin,
            admin: attributes['custom:admin'],
            auto: attributes['custom:auto'] ? attributes['custom:auto'] : 'no',
            firstDay: attributes['custom:firstDay'] ? attributes['custom:firstDay'] : '2019-5-13',
            jobSchedule: attributes['custom:jobSchedule'] ? attributes['custom:jobSchedule'] : 'GENERAL'
        });
    };

    loadSummary = async () => {
        try {
            let response = await TimeControlService.getSummary(new Date().getFullYear());
            // console.log('loadSummary', response);
            this.setState({ summary: response });
        } catch (error) {
            console.log(error);
        }
    };

    loadToday = async () => {
        let holidaysResponse = [];
        try {
            holidaysResponse = await TimeControlService.getHolidays(this.addDays(new Date()).getFullYear());
            // console.log('holidaysResponse', holidaysResponse);
        } catch (error) {
            console.log(error.response);
        }

        try {
            let response = await TimeControlService.getLog(this.addDays(new Date()));
            // console.log('getLog', response);
            if (holidaysResponse.indexOf(response.date) !== -1) {
                response.isHoliday = true;
            }
            this.setState({ log: response });
        } catch (error) {
            console.log(error.response);

            // init log values
            let now = this.addDays(new Date());
            let year = now.getFullYear();
            let month = ('0' + (now.getMonth() + 1)).slice(-2);
            let day = ('0' + now.getDate()).slice(-2);
            let date = `${year}-${month}-${day}`;
            this.setState({
                log: {
                    date: date,
                    dayType: 'N',
                    entries: [],
                    isHoliday: holidaysResponse.indexOf(date) !== -1
                }
            });
        }
    };

    handleEnterAction = async () => {
        // if (this.state.dayTranslate === 0) {
        //     await this.doEnterAction(new Date());
        // } else {
        // ask for time
        this.setState({ showTimePicker: true, timePickerAction: 'enter', selectedPickerDate: new Date() });
        //}
    };

    handleCloseTimePicker = () => {
        this.setState({ showTimePicker: false });
    };

    handleTimePickerChange = date => {
        this.setState({ selectedPickerDate: date });
    };

    handleTimePickerDone = async () => {
        //get time from picker
        let date = this.state.selectedPickerDate;
        if (this.state.timePickerAction === 'enter') {
            await this.doEnterAction(date);
        } else {
            await this.doLeaveAction(date);
        }
        this.setState({ showTimePicker: false, timePickerAction: undefined });
    };

    doEnterAction = async time => {
        let newEntry = {
            date: this.addDays(time).toISOString(),
            action: 'enter'
        };
        await this.handleAddLogEntryAction('N', newEntry);
    };

    handleLeaveAction = async () => {
        // if (this.state.dayTranslate === 0) {
        //     this.doLeaveAction(new Date());
        // } else {
        // ask for time
        this.setState({ showTimePicker: true, timePickerAction: 'leave', selectedPickerDate: new Date() });
        // }
    };

    doLeaveAction = async date => {
        let newEntry = {
            date: this.addDays(date).toISOString(),
            action: 'leave'
        };
        await this.handleAddLogEntryAction('N', newEntry);
    };

    handleAddLogEntryAction = async (dayType, entry) => {
        try {
            let newLog = cloneDeep(this.state.log);
            newLog.dayType = dayType;
            newLog.entries.push(entry);
            let response = await TimeControlService.setLog(newLog);
            // console.log('response', response);
            this.setState({ log: response });
        } catch (error) {
            console.log(error.response);
            await this.loadToday();
        }
    };

    canDoAction = action => {
        let entries = this.state.log !== undefined ? this.state.log.entries : [];
        entries.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        let lastEntry = entries[0];

        if (lastEntry) {
            let lastAction = lastEntry.action;
            if (lastAction === action) {
                return false;
            }
        } else if ('leave' === action) {
            return false;
        }
        return true;
    };

    canDoOtherLogTypes = () => {
        let entries = this.state.log !== undefined ? this.state.log.entries : [];
        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i];
            if (entry.action === 'leave' || entry.action === 'enter') {
                return false;
            }
        }
        return true;
    };

    isPastDay = () => {
        if (this.state.isAdmin === true) {
            return false;
        }
        let log = this.state.log ? this.state.log : {};
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (new Date(log.date) < today) {
            return true;
        }
        return false;
    };

    isDayClosed = () => {
        let log = this.state.log ? this.state.log : {};
        if (log.dayType !== 'N') {
            return true;
        }
        return false;
    };

    handleBaixaAction = () => {
        this.handleSetLogAction('B');
    };

    handleViatgeAction = () => {
        this.handleSetLogAction('V');
    };

    handleVacasAction = () => {
        this.handleSetLogAction('VC');
    };

    handleSaveSettings = async () => {
        try {
            await TimeControlService.saveSettings(this.state.admin, this.state.auto, this.state.firstDay, this.state.jobSchedule);
            alert('Ajustos desats');
        } catch (err) {
            alert('Error');
        }
    };

    handleSetLogAction = async dayType => {
        try {
            let newLog = {
                date: this.state.log.date,
                dayType,
                entries: []
            };

            let response = await TimeControlService.setLog(newLog);
            // console.log('response', response);
            this.setState({ log: response });
        } catch (error) {
            console.log(error);
            await this.loadToday();
        }
    };

    handleDeleteLogEntry = async date => {
        if (window.confirm('Segur que vols esborrar aquesta entrada?')) {
            try {
                let newLog = cloneDeep(this.state.log);
                newLog.entries = newLog.entries.filter(entry => {
                    return entry.date !== date;
                });
                let response = await TimeControlService.setLog(newLog);
                // console.log('response', response);
                this.setState({ log: response });
            } catch (error) {
                console.log(error.response);
                await this.loadToday();
            }
        }
    };

    moveDayBack = () => {
        this.setState({ dayTranslate: this.state.dayTranslate - 1 }, this.loadToday);
    };

    moveDayForward = () => {
        this.setState({ dayTranslate: this.state.dayTranslate + 1 }, this.loadToday);
    };

    addDays = parDate => {
        var date = new Date(parDate);
        date.setDate(date.getDate() + this.state.dayTranslate);
        return date;
    };

    handleDeleteDay = async () => {
        if (window.confirm('Segur que vols esborrar les dades de tot el dia?')) {
            // init log values
            let now = this.addDays(new Date());
            let year = now.getFullYear();
            let month = ('0' + (now.getMonth() + 1)).slice(-2);
            let day = ('0' + now.getDate()).slice(-2);
            let date = `${year}-${month}-${day}`;
            let newLog = {
                date: date,
                dayType: 'N',
                entries: []
            };
            let response = await TimeControlService.setLog(newLog);
            // console.log('response', response);
            this.setState({ log: response });
        }
    };

    getLang = () => {
        if (navigator.languages !== undefined) return navigator.languages[0];
        else return navigator.language;
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        const { classes } = this.props;
        const { log, summary } = this.state;
        return (
            <div className={classes.container}>
                {log ? (
                    <div>
                        <h2>
                            {this.state.dayTranslate === 0 ? 'Avui' : 'Dia'}
                            <br />
                            <span style={{ textTransform: 'capitalize' }}>
                                {new Date(log.date).toLocaleDateString(this.getLang(), { weekday: 'long' })}
                            </span>
                            <br />
                            <IconButton aria-label="Esborrar" className={classes.margin} onClick={this.moveDayBack}>
                                <ArrowBack fontSize="small" />
                            </IconButton>
                            {new Date(log.date).toLocaleDateString()}
                            <IconButton aria-label="Esborrar" className={classes.margin} onClick={this.moveDayForward}>
                                <ArrowForward fontSize="small" />
                            </IconButton>
                            <br />
                            {log.isHoliday === true && 'Festa'}
                        </h2>

                        <div>{dayTypes[log.dayType]}</div>
                        <br />
                        <div>
                            {log.entries
                                .sort((a, b) => {
                                    return new Date(b.date) - new Date(a.date);
                                })
                                .reverse()
                                .map((entry, i) => {
                                    let isDeletable = false;
                                    let currDate = new Date(entry.date);
                                    let today = new Date();
                                    today.setHours(0, 0, 0, 0);

                                    isDeletable =
                                        i === log.entries.length - 1 && (this.state.isAdmin === true ? true : !(currDate < today));

                                    return (
                                        <div key={entry.date}>
                                            {`${actionTypes[entry.action]} - ${currDate.toLocaleTimeString()}`}
                                            {isDeletable === true ? (
                                                <IconButton
                                                    aria-label="Esborrar"
                                                    className={classes.margin}
                                                    onClick={() => this.handleDeleteLogEntry(entry.date)}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                        <br />
                        <div>
                            <b>{`Temps total: ${log.totalHours ? log.totalHours : 0}h`}</b>
                        </div>
                    </div>
                ) : (
                    'Carregant...'
                )}
                {summary && (
                    <div
                        className={classes.summary}
                        style={{
                            border: `1px solid ${summary.workHoursBalance >= 0 ? '#009900' : '#e50000'}`,
                            color: summary.workHoursBalance >= 0 ? '#009900' : '#e50000'
                        }}
                    >
                        {summary.workHoursBalance}
                        {' h'}
                    </div>
                )}
                <br />
                <Button
                    variant="contained"
                    disabled={!this.canDoAction('enter') || this.isDayClosed() || this.isPastDay()}
                    color="primary"
                    className={classes.button}
                    onClick={this.handleEnterAction}
                >
                    {'Entrada'}
                </Button>
                <Button
                    variant="contained"
                    disabled={!this.canDoAction('leave') || this.isDayClosed() || this.isPastDay()}
                    color="primary"
                    className={classes.button}
                    onClick={this.handleLeaveAction}
                >
                    {'Sortida'}
                </Button>
                <br />
                <br />
                <br />
                <Button
                    variant="contained"
                    disabled={!this.canDoOtherLogTypes() || this.isDayClosed() || this.isPastDay()}
                    className={classes.button}
                    onClick={this.handleBaixaAction}
                >
                    {'Baixa Laboral'}
                </Button>
                <Button
                    variant="contained"
                    disabled={!this.canDoOtherLogTypes() || this.isDayClosed() || this.isPastDay()}
                    className={classes.button}
                    onClick={this.handleViatgeAction}
                >
                    {'Viatge Laboral'}
                </Button>
                <br />
                <Button
                    variant="contained"
                    disabled={!this.canDoOtherLogTypes() || this.isDayClosed() || this.isPastDay()}
                    className={classes.button}
                    onClick={this.handleVacasAction}
                >
                    {'Vacances'}
                </Button>
                {/* <Button variant="contained"  className={classes.button} >
                    {'Compensar Dia'}
                </Button> 
                <br />*/}
                <Link to="/reports">
                    <Button variant="contained" className={classes.button}>
                        {'Informes'}
                    </Button>
                </Link>
                <br />
                <Button
                    variant="contained"
                    disabled={this.isPastDay()}
                    color="secondary"
                    className={classes.button}
                    onClick={this.handleDeleteDay}
                >
                    {'Esborrar dia'}
                </Button>
                <br />
                <br />
                {(this.state.isAdmin === true || this.state.isAdmin === false) && (
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className={classes.heading}>Ajustos</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div style={{ width: '100%' }}>
                                <TextField
                                    label="Primer dia de feina"
                                    fullWidth
                                    margin="normal"
                                    name="firstDay"
                                    defaultValue={this.state.firstDay}
                                    value={this.state.firstDay}
                                    onChange={evt => this.handleChange(evt)}
                                />
                                <TextField
                                    label="Admin"
                                    fullWidth
                                    select
                                    name="admin"
                                    margin="normal"
                                    value={this.state.admin}
                                    onChange={evt => this.handleChange(evt)}
                                >
                                    <MenuItem key="yes" value="yes">
                                        Sí
                                    </MenuItem>
                                    <MenuItem key="no" value="no">
                                        No
                                    </MenuItem>
                                </TextField>
                                <TextField
                                    label="Entrada automàtica"
                                    fullWidth
                                    select
                                    margin="normal"
                                    name="auto"
                                    value={this.state.auto}
                                    onChange={evt => this.handleChange(evt)}
                                >
                                    <MenuItem key="yes" value="yes">
                                        Sí
                                    </MenuItem>
                                    <MenuItem key="no" value="no">
                                        No
                                    </MenuItem>
                                </TextField>

                                <TextField
                                    label="Horari"
                                    fullWidth
                                    select
                                    margin="normal"
                                    name="jobSchedule"
                                    value={this.state.jobSchedule}
                                    onChange={evt => this.handleChange(evt)}
                                >
                                    <MenuItem key="GENERAL" value="GENERAL">
                                        General
                                    </MenuItem>
                                    <MenuItem key="ADMINISTRATION" value="ADMINISTRATION">
                                        Administració
                                    </MenuItem>
                                </TextField>
                                <br />
                                <Button variant="contained" className={classes.button} onClick={this.handleSaveSettings}>
                                    {'Desa ajustos'}
                                </Button>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                <Dialog
                    open={this.state.showTimePicker}
                    onClose={this.handleCloseTimePicker}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Hora'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'Escull una hora per a '}
                            {actionTypes[this.state.timePickerAction]}
                        </DialogContentText>
                        <br />
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                fullWidth
                                margin="normal"
                                ampm={false}
                                label={`Hora ${actionTypes[this.state.timePickerAction]}`}
                                value={this.state.selectedPickerDate}
                                onChange={this.handleTimePickerChange}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseTimePicker} color="primary">
                            {'Cancelar'}
                        </Button>
                        <Button onClick={this.handleTimePickerDone} color="primary" autoFocus>
                            {'Acceptar'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
TodayControl.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(TodayControl);
