import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Button, TextField } from '@material-ui/core';

import './Login.css';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: '',
            password: ''
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = (event, id) => {
        this.setState({
            [id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.signIn(this.state.email, this.state.password);
            this.props.userHasAuthenticated(true);
            this.props.history.push('/');
        } catch (e) {
            console.log(e.message);
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <div className='Login'>
                <h1>{'Inicia Sessió'}</h1>
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        fullWidth
                        autoFocus
                        label='Correu Electrònic'
                        type='email'
                        value={this.state.email}
                        onChange={(evt) => this.handleChange(evt, 'email')}
                    />
                    <br />
                    <TextField
                        fullWidth
                        label='Contrasenya'
                        value={this.state.password}
                        onChange={(evt) => this.handleChange(evt, 'password')}
                        type='password'
                    />
                    <br />
                    <Button
                        disabled={!this.validateForm()}
                        type='submit'
                    >{this.state.isLoading === true ? 'Iniciant...' : 'Inciar Sessió'}</Button>
                </form>
            </div>
        );
    }
}
Login.propTypes = {
    userHasAuthenticated: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};
export default Login;