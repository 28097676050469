import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import Routes from './Components/Router/Routes';
import { Link, withRouter } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HowToReg from '@material-ui/icons/HowToReg';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ExitToApp from '@material-ui/icons/ExitToApp';
import './App.css';

const ICON_COLOR = '#fff';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true
        };
    }

    async componentDidMount() {
        try {
            await Auth.currentSession();
            this.userHasAuthenticated(true);
        }
        catch (e) {
            if (e !== 'No current user') {
                console.log(e);
            }
        }

        this.setState({ isAuthenticating: false });
    }

    userHasAuthenticated = authenticated => {
        this.setState({ isAuthenticated: authenticated });
    }

    handleLogout = async event => {
        await Auth.signOut();

        this.userHasAuthenticated(false);

        this.props.history.push('/login');
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated
        };

        return (!this.state.isAuthenticating &&
            <div>
                <AppBar position="fixed" className='no-print'>
                    <Toolbar style={{ color: '#fff' }}>
                        <div style={{ display: 'inline-block', position: 'absolute', left: '10px' }}>
                            <IconButton aria-label="Go Back" onClick={this.handleBack} style={{ color: ICON_COLOR }}>
                                <ArrowBack />
                            </IconButton>
                        </div>

                        <div style={{ textAlign: 'center', margin: 'auto' }} >
                            {'Control de Presència'}
                        </div>
                        <div style={{ display: 'inline-block', position: 'absolute', right: '10px' }}>
                            {this.state.isAuthenticated ?
                                <IconButton aria-label="Tanca Sessió" onClick={this.handleLogout} style={{ color: ICON_COLOR }}>
                                    <ExitToApp />
                                </IconButton>
                                :
                                <Link to='/signup'>
                                    <IconButton aria-label="Registra\'t" style={{ color: ICON_COLOR }}>
                                        <HowToReg />
                                    </IconButton>
                                </Link>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                <div className='app-body-container'>
                    <Routes childProps={childProps} />
                </div>
            </div>
        );
    }
}
App.propTypes = {
    history: PropTypes.object.isRequired,
};
export default withRouter(App);;
