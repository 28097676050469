import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TodayControl from './TodayControl';
import './Home.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.setState({ isLoading: false });
  }

  renderLander() {
    return (
      <div className='lander'>
        <h1>{'Benvingut/da'}</h1>
        <p>{'Per començar has d\'iniciar sessió o registrar-te'}</p>
        <div>
          <Link to='/login' className='btn btn-info btn-lg'>
            {'Inicia Sessió'}
          </Link>
          <Link to='/signup' className='btn btn-success btn-lg'>
            {'Registra\'t'}
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className='Home'>
        {this.props.isAuthenticated ?
          this.state.isLoading === true ?
            'Loading'
            :
            <TodayControl />
          :
          this.renderLander()
        }
      </div>
    );
  }
}
Home.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};
export default Home;