import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TimeControlService from '../ApiClients/TimeControlService';
import Month from './Month';
import { Auth } from 'aws-amplify';
import { MONTHS, YEARS } from './Types';
import './MonthlyReports.css';

class MonthlyReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: 'all',
            user: undefined,
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            isAdmin: false
        };
    }

    async componentDidMount() {
        let holidays = await TimeControlService.getHolidays(this.state.year);
        // console.log('holidays', holidays)
        this.setState({ holidays });
        let isAdmin = await this.loadUserData();
        if (isAdmin === true) {
            await this.loadAdminAllLogs();
        } else {
            await this.loadAllUserLogs();
        }
    }

    loadUserData = async () => {
        let user = await Auth.currentAuthenticatedUser();
        const { attributes } = user;
        //console.log('user', user)
        let isAdmin = false;
        if (attributes['custom:admin'] === 'yes') {
            isAdmin = true;
        }
        this.setState({ user: attributes, isAdmin, selectedUser: attributes.sub });
        return isAdmin;
    };

    loadAdminAllLogs = async () => {
        try {
            let logs = await TimeControlService.adminGetAllLogs();
            let users = await TimeControlService.getAllUsers();
            // console.log('loadAdminAllLogs', logs, users);
            this.setState({ logs, users });
        } catch (error) {
            console.log(error);
        }
    };

    loadAllUserLogs = async () => {
        try {
            let response = await TimeControlService.getAllLogs();
            // console.log('loadAllUserLogs', response);
            this.setState({ logs: response });
        } catch (error) {
            console.log(error);
        }
    };

    handleChangeUser = event => {
        this.setState({ selectedUser: event.target.value });
        TimeControlService.setReview(event.target.value).then(() => 'ok');
    };

    handleChangeMonth = event => {
        this.setState({ month: Number(event.target.value) });
    };

    handleChangeYear = event => {
        this.setState({ year: Number(event.target.value) });
    };

    renderMonthComponents = () => {
        const { logs, user, month, year, isAdmin, users, selectedUser } = this.state;
        if (isAdmin === true && users) {
            if (selectedUser === 'all') {
                return users.map(currUser => {
                    return (
                        <Month
                            month={month}
                            year={year}
                            user={currUser}
                            logs={logs.filter(log => {
                                return log.userSub === currUser.userSub;
                            })}
                            holidays={this.state.holidays}
                        />
                    );
                });
            } else {
                let currUser = users.filter(aux => {
                    return aux.userSub === selectedUser;
                });
                currUser = currUser[0];
                if (currUser) {
                    return (
                        <Month
                            month={month}
                            year={year}
                            user={currUser}
                            logs={logs.filter(log => {
                                return log.userSub === currUser.userSub;
                            })}
                            holidays={this.state.holidays}
                        />
                    );
                }
            }
        } else {
            return <Month month={month} year={year} user={user} logs={logs} holidays={this.state.holidays} />;
        }
    };

    compare = (a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    };

    render() {
        const { month, year, isAdmin, users, selectedUser } = this.state;
        return (
            <div>
                <div className="no-print" style={{ padding: '15px' }}>
                    {isAdmin === true && users !== undefined && (
                        <FormControl>
                            <InputLabel>{'Treballador:'}</InputLabel>
                            <Select value={selectedUser} onChange={this.handleChangeUser}>
                                <MenuItem value="all">{'Tots'}</MenuItem>
                                {users.sort(this.compare).map((currUser, i) => {
                                    return (
                                        <MenuItem key={currUser.userSub} value={currUser.userSub}>
                                            {currUser.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                    {'   '}
                    <FormControl>
                        <InputLabel>{'Mes:'}</InputLabel>
                        <Select value={month} onChange={this.handleChangeMonth}>
                            {MONTHS.map((month, i) => {
                                return (
                                    <MenuItem key={i} value={i}>
                                        {month}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {'   '}
                    <FormControl>
                        <InputLabel>{'Any:'}</InputLabel>
                        <Select value={year} onChange={this.handleChangeYear}>
                            {YEARS.map((year, i) => {
                                return (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                </div>
                {this.renderMonthComponents()}
            </div>
        );
    }
}
MonthlyReports.propTypes = {};
export default MonthlyReports;
