import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import config from './config';
import './index.css';

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    API: {
        endpoints: config.apiGateway.map((endpoint) => {
            return {
                ...endpoint,
                custom_header: async () => {
                    return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
                }
            };
        }),
    }
});

class AppContainer extends Component {
    render() {
        return (
            <div>
                <Router>
                    <App />
                </Router>
            </div>
        );
    }
}

ReactDOM.render(<AppContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
