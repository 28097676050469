import { API } from 'aws-amplify';

const API_NAME = 'TimeControlService';

class TimeControlService {
    static adminGetAllLogs = year => {
        let path = `/all-logs/${year ? year : ''}`;
        let myInit = {
            body: {},
            headers: {},
            response: false
        };

        return API.get(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static getAllUsers = () => {
        let path = '/all-users';
        let myInit = {
            body: {},
            headers: {},
            response: false
        };

        return API.get(API_NAME, path, myInit).then(response => {
            // Add your code here
            let result = response.map(user => {
                let newUser = {};
                user.Attributes.forEach(attr => {
                    let key = attr.Name;
                    if (key === 'sub') {
                        key = 'userSub';
                    }
                    let value = attr.Value;
                    newUser[key] = value;
                });
                Object.keys(user).forEach(key => {
                    if (key !== 'Attributes') {
                        newUser[key] = user[key];
                    }
                });
                return newUser;
            });
            return result;
        });
    };

    static getAllLogs = year => {
        let path = `/logs/${year ? year : ''}`;
        let myInit = {
            body: {},
            headers: {},
            response: false
        };

        return API.get(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static getLog = date => {
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2);
        let day = ('0' + date.getDate()).slice(-2);

        let path = `/${year}-${month}-${day}`;
        let myInit = {
            headers: {},
            response: false
        };

        return API.get(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static setLog = log => {
        let path = `/${log.date}`;
        let myInit = {
            body: {
                ...log
            },
            headers: {},
            response: false
        };

        return API.put(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static deleteLog = log => {
        let year, month, day;
        let path = `/${year}-${month}-${day}`;
        let myInit = {
            body: {},
            headers: {},
            response: false
        };

        return API.delete(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static getSummary = year => {
        let path = `/summary/${year}`;
        let myInit = {
            headers: {},
            response: false
        };

        return API.get(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static getHolidays = year => {
        let path = `/holidays/${year}`;
        let myInit = {
            headers: {},
            response: false
        };

        return API.get(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static setReview = userSubChecked => {
        let path = `/review/${userSubChecked}`;
        let myInit = {
            body: {},
            headers: {},
            response: false
        };

        return API.put(API_NAME, path, myInit).then(response => {
            // Add your code here
            return response;
        });
    };

    static saveSettings = (admin, auto, firstDay, jobSchedule) => {
        let path = `/update-user/${admin}/${auto}/${jobSchedule}/${firstDay}`;
        let myInit = {
            headers: {},
            response: false
        };

        return API.get(API_NAME, path, myInit).then(response => {
            return response;
        });
    };
}
export default TimeControlService;
