import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from '../Home';
import ReportsHome from '../ReportsHome';
import MonthlyReports from '../MonthlyReports';
import HourPool from '../HourPool';
import Login from '../Auth/Login';
import Signup from '../Auth/Signup';
import NotFound from '../Router/NotFound';
import AppliedRoute from '../Router/AppliedRoute';
import AuthenticatedRoute from '../Router/AuthenticatedRoute';
import UnauthenticatedRoute from '../Router/UnauthenticatedRoute';

const Routes = ({ childProps }) => (
    <Switch>
        <Redirect from='/index.html' to='/' exact />
        <AppliedRoute path='/' exact component={Home} props={childProps} />
        <UnauthenticatedRoute path='/login' exact component={Login} props={childProps} />
        <UnauthenticatedRoute path='/signup' exact component={Signup} props={childProps} />
        <AuthenticatedRoute path='/reports' exact component={ReportsHome} props={childProps} />
        <AuthenticatedRoute path='/reports/month' exact component={MonthlyReports} props={childProps} />
        <AuthenticatedRoute path='/reports/hourpool' exact component={HourPool} props={childProps} />
        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
    </Switch>
);
Routes.propTypes = {
    childProps: PropTypes.object.isRequired,
};
export default Routes;