import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import HourPool from './HourPool';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    container: {
        padding: theme.spacing.unit,
        textAlign: 'center'
    },
});

class ReportsHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <HourPool />
                <Link to='/reports/month'>
                    <Button variant="contained" color="primary" className={classes.button}>
                        {'Informe Mensual'}
                    </Button>
                </Link>
                {/* <Link to='/reports/hourpool'>
                    <Button variant="contained" color="primary" className={classes.button}>
                        {'Bossa d\'hores'}
                    </Button>
                </Link> */}
            </div>
        );
    }
}
ReportsHome.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ReportsHome);