import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const AppliedRoute = ({ component: C, props: cProps, ...rest }) => (
    <Route {...rest} render={props => <C {...props} {...cProps} />} />
);
AppliedRoute.propTypes = {
    component: PropTypes.any.isRequired,
    props: PropTypes.any.isRequired,
};
export default AppliedRoute;