import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import './Signup.css';

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      newUser: null
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = (event, id) => {

    this.setState({
      [id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          name: this.state.name,
          email: this.state.email,
        },
      });
      this.setState({
        newUser
      });
    } catch (e) {
      console.log(e.message);
    }

    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      this.props.userHasAuthenticated(true);
      this.props.history.push('/');
    } catch (e) {
      console.log(e.message);
      this.setState({ isLoading: false });
    }
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>

        <TextField
          fullWidth
          autoFocus
          label="Codi de verificació"
          type='tel'
          value={this.state.confirmationCode}
          onChange={(evt) => this.handleChange(evt, 'confirmationCode')}
        />
        <br />
        <br />
        <div>{'* Comproba el teu correu electrònic, allà rebràs el codi de verificació.'}</div>
        <br />
        <Button
          disabled={!this.validateConfirmationForm()}
          type='submit'
        >{this.state.isLoading === true ? 'Verificant...' : 'Verifica'}</Button>
      </form>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          label="Nom i Cognoms"
          type='text'
          value={this.state.name}
          onChange={(evt) => this.handleChange(evt, 'name')}
        />
        <br />
        <TextField
          fullWidth
          label="Correu electrònic"
          type='email'
          value={this.state.email}
          onChange={(evt) => this.handleChange(evt, 'email')}
        />
        <br />
        <TextField
          fullWidth
          value={this.state.password}
          onChange={(evt) => this.handleChange(evt, 'password')}
          label="Contrasenya"
          type='password'
        />
        <br />
        <TextField
          fullWidth
          value={this.state.confirmPassword}
          onChange={(evt) => this.handleChange(evt, 'confirmPassword')}
          label="Repeteix contrasenya"
          type='password'
        />
        <br />
        <Button
          disabled={!this.validateForm()}
          type='submit'
        >{this.state.isLoading === true ? 'Registrant...' : 'Registra\'m'}</Button>
      </form>
    );
  }

  render() {
    return (
      <div className='Signup'>
        <h1>{'Registra\'t'}</h1>
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
Signup.propTypes = {
  userHasAuthenticated: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
export default Signup;